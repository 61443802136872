* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  height: 100vh;
  position: relative;
}
.ocrloader {
  width:  99%;
  height: 99%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  backface-visibility: hidden;
}
.ocrloader span {
  position: absolute;
  left: 2px;
  top: 0;
  width: 100%;
  height: 3px;
  background: radial-gradient(#5b7c52 -24%, #66ff33 100%);
  z-index: 1;
  animation: move 2.5s cubic-bezier(0.15, 0.44, 0.76, 0.64);
  animation-iteration-count: infinite;
}
.ocrloader > div {
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 48%;
  backface-visibility: hidden;
}
.ocrloader:before,
.ocrloader:after,
.ocrloader em:after,
.ocrloader em:before {
  border-color: #1AAD19;
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-style: solid;
  border-width: 0px;
}
.ocrloader:before {
  left: 0;
  top: 0;
  border-left-width: 2px;
  border-top-width: 2px;
}
.ocrloader:after {
  right: 0;
  top: 0;
  border-right-width: 2px;
  border-top-width: 2px;
}
.ocrloader em:before {
  left: 0;
  bottom: 0;
  border-left-width: 2px;
  border-bottom-width: 2px;
}
.ocrloader em:after {
  right: 0;
  bottom: 0;
  border-right-width: 2px;
  border-bottom-width: 2px;
}
@keyframes move {
  0%,
  100% {
    transform: translateY(16790%);
  }
  0% {
    transform: translateY(0%);
  }
}